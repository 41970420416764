import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Toast } from 'primereact/toast';
import { clientAuthSaldos as client } from '../../client';
import * as projectAction from '../../store/actions/projectAction';
import DatosClienteTable from './DatosClienteTable';
import WalletsClienteTable from './WalletsClienteTable';
import { getClienteWallet } from '../../graphql/queries';
import LoadingSpinner from '../../components/LoadingSpinner';
import MovementsTable from './MovementsTable';
import { cliente } from '../../configs/textos';

const DatosCliente = ({ global }) => {
	const mockData = {
		getCustomerWallets: {
			wallets: [
				{
					wallet_id: 348,
					archived: false,
					blackList: null,
					current_units_balance: 5.0,
					exp_date: '3021-02-04T23:59:59.999Z',
					init_date: '2022-10-04T09:08:26.551Z',
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					pocket_name: '{"en":"Pocket Soria","es":"Pocket Soria"}',
					state: '{"en":"Active","es":"Activo"}',
					unit_type_id: 1,
					unit_type_short_name: '€',
					wallet_def_id: 20,
					wallet_serial_number: '975200000000348',
					check_digit: 4,
				},
			],
		},
		getCustomerCertificates: {
			Dem_certificate_category: {
				items: [
					{
						category_multiple_types: false,
						certificate_category_id: 2,
						name: '{"en":"Photo","es":"Fotografía"}',
						Dem_certificate_type: {
							items: [
								{
									c_order: 2,
									certificate_type_id: 2,
									description:
										'{"en":"Fotografía de carnet.","es":"Fotografía de carnet."}',
									name: '{"en":"Fotografía","es":"Fotografía"}',
									Cust_named_customer_certificate: {
										items: [
											{
												exp_date: '2027-10-06T23:59:59.999Z',
												init_date: '2022-10-07T17:40:21.856Z',
												named_customer_certificate_id: 287,
												value: null,
												Cust_named_customer_certificate_operation: {
													certificate_denial_description: 'prueba',
													timestamp: '2022-10-07T18:05:48.975Z',
													Dem_certificate_denial_type: {
														name: '{"en":"No valid documents","es":"Documentos no válidos"}',
													},
													Cust_named_customer_certificate_state: {
														named_customer_certificate_state_id: 3,
														name: '{"en":"Denied","es":"Denegado"}',
														text_color: '#B02A2A',
														web_color: '#FFCDD2',
													},
												},
											},
										],
									},
								},
							],
						},
					},
					{
						category_multiple_types: false,
						certificate_category_id: 3,
						name: '{"en":"Canet de Familia Numerosa EN","es":"Canet de Familia Numerosa"}',
						Dem_certificate_type: {
							items: [
								{
									c_order: 3,
									certificate_type_id: 3,
									description:
										'{"en":"El reconocimiento de la condición de familia numerosa permite solicitar ayudas económicas y otras medidas de apoyo establecidas por ley.","es":"El reconocimiento de la condición de familia numerosa permite solicitar ayudas económicas y otras medidas de apoyo establecidas por ley."}',
									name: '{"en":"Carnet de Familia Numerosa","es":"Carnet de Familia Numerosa"}',
									Cust_named_customer_certificate: {
										items: [
											{
												exp_date: '2025-10-06T23:59:59.999Z',
												init_date: '2022-10-07T00:00:00.000Z',
												named_customer_certificate_id: 282,
												value: null,
												Cust_named_customer_certificate_operation: {
													certificate_denial_description: null,
													timestamp: '2022-10-07T09:28:36.985Z',
													Dem_certificate_denial_type: {
														name: null,
													},
													Cust_named_customer_certificate_state: {
														named_customer_certificate_state_id: 1,
														name: '{"en":"Validated","es":"Validado"}',
														text_color: '#2C500D',
														web_color: '#B3C99F',
													},
												},
											},
										],
									},
								},
							],
						},
					},
					{
						category_multiple_types: false,
						certificate_category_id: 6,
						name: '{"en":"Certificado de ingresos EN","es":"Certificado de ingresos"}',
						Dem_certificate_type: {
							items: [
								{
									c_order: 6,
									certificate_type_id: 6,
									description:
										'{"en":"Certificado de ingresos del último ejercicio.","es":"Certificado de ingresos del último ejercicio."}',
									name: '{"en":"Certificado de ingresos","es":"Certificado de ingresos"}',
									Cust_named_customer_certificate: {
										items: [
											{
												exp_date: '2023-10-07T23:59:59.999Z',
												init_date: '2022-10-07T06:38:46.358Z',
												named_customer_certificate_id: 276,
												value: null,
												Cust_named_customer_certificate_operation: {
													certificate_denial_description: 'Imagen no válida',
													timestamp: '2022-10-07T06:39:03.866Z',
													Dem_certificate_denial_type: {
														name: '{"en":"No valid documents","es":"Documentos no válidos"}',
													},
													Cust_named_customer_certificate_state: {
														named_customer_certificate_state_id: 3,
														name: '{"en":"Denied","es":"Denegado"}',
														text_color: '#B02A2A',
														web_color: '#FFCDD2',
													},
												},
											},
										],
									},
								},
							],
						},
					},
				],
			},
		},
		getCustomerData: {
			birth_date: null,
			customer_id: 207,
			document_id: null,
			email: 'adela_saavedra@yopmail.com',
			name: 'Adela',
			photo: null,
			surname: 'Saavedra',
			tel_code: '',
			tel_code_id: 1,
			tel_number: null,
			register_type_id: 1,
			blackList: null,
		},
		listCustomerMovements: {
			items: [
				{
					amount: 5.0,
					name: '{"en":"Tarjeta Bus EN","es":"Tarjeta Bus"}',
					wallet_serial_number: '975200000000348',
					check_digit: 4,
					date_time: '2022-10-04T09:08:06.953Z',
					available_balance: 5.0,
					linea: null,
					bus: null,
					description: null,
					transaction_id: 'VTWsiHZGtpHM',
					unit_type_short_name: '€',
					validation_id: null,
					wallet_transaction_id: 3149,
					wallet_transaction_state_name:
						'{"en": "Successful operation", "es": "Operación realizada"}',
					wallet_transaction_type_name: '{"en":"Purchase","es":"Compra"}',
				},
			],
		},
	};
	const useMockData = false;
	const { customerId } = useParams();
	const { networkId } = global;
	const { t } = useTranslation();
	const toast = useRef(null);
	const [errorState, setErrorState] = useState(null);

	const { data, loading, error } = useMockData
		? { data: mockData, loading: false }
		: useQuery(getClienteWallet, {
				client,
				fetchPolicy: 'network-only',
				variables: { customerId, networkId },
		  });

	useEffect(() => {
		if (error) {
			setErrorState(error.message);
		}
	}, [error]);

	if (errorState) {
		showErrorToast(errorState);
		setErrorState(null);
	}

	if (data?.getCustomerData == null && loading) return <LoadingSpinner />;
	if (
		error?.graphQLErrors != null &&
		error.graphQLErrors.some(
			({ errorType }) => errorType === 'CustomerNotInNetworkError'
		)
	)
		return <Redirect to={cliente.url} />;

	const wallets = data?.listCust_pockets.items[0].Cust_wallet.items;
	const lastMovements = data?.listCom_wallet_transactions.items;

	function showErrorToast(e) {
		toast?.current?.show({
			severity: 'error',
			summary: 'Error',
			detail: e,
			life: 3000,
		});
	}

	return (
		<div>
			<div className='p-container'>
				<Toast ref={toast} />
				{!loading && (
					<>
						<DatosClienteTable cliente={wallets} />
						<div className=''>
							<WalletsClienteTable wallet={wallets} customerId={customerId} />
							{/* <CertificadosClienteTable
								certificates={mapCertificates}
								cliente={customer}
							/> */}
							<MovementsTable
								movements={lastMovements}
								customerId={customerId}
								lastMovements
								title={t('Últimos movimientos')}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
};
const mapStateToProps = (rootReducer) => ({ global: rootReducer });
const mapDispatchToProps = projectAction;

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTranslation('translations')(DatosCliente));
